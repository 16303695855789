<ion-app>
  <ion-split-pane>
      <ion-menu>
          <ion-header>
            <ion-toolbar>
              <ion-title>Menu</ion-title>
            </ion-toolbar>
          </ion-header>
          <ion-content>
             
            <ion-list>
              <ion-list-header>
                Navigate
              </ion-list-header>
              <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
                <ion-item (click)="onMenuClick(p.url, p.id)" style="cursor: pointer;">
                    <ion-icon icon slot="start"   src="../assets/icon/{{p.icon}}"  alt="community" ></ion-icon>
                   <!--  
                    <span slot="start">   <div  innerHtml="{{p.icon}}" ></div> </span> 
                     [routerLink]="p.url"
                   <ion-icon slot="start" id='icoComp{{p.title}}' [name]="p.icon"></ion-icon> 
                  <span slot="start"><i id='icoCompHome' class="p.icon" ></i></span>-->
                  <ion-label id='{{p.Title}}' >
                    {{p.title}}
                  </ion-label>
                </ion-item>
    
              </ion-menu-toggle>
            </ion-list>
    
            <ion-list *ngIf="loggedIn">
              <ion-list-header>
                Account
              </ion-list-header>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/account">
                  <ion-icon slot="start" name="person"></ion-icon> 
                  <ion-label>
                    Account
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-menu-toggle auto-hide="false"> 
                <ion-item routerLink="/profile-edit">
                    <ion-icon slot="start" name="person"></ion-icon>
                    <ion-label>Profile</ion-label>
                  </ion-item>
                </ion-menu-toggle>
              <!--  ADMIN 
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/admin">
                  <ion-icon slot="start" name="person"></ion-icon>
                  <ion-label>
                    Admin
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
               /ADMIN -->
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/support">
                  <ion-icon slot="start" name="help"></ion-icon>
                  <ion-label>
                    Support
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item button (click)="logout()">
                  <ion-icon slot="start" name="log-out"></ion-icon>
                  <ion-label>
                    Logout
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
    
            </ion-list>
    
            <ion-list *ngIf="!loggedIn">
              <ion-list-header>
                Account
              </ion-list-header>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/login">
                  <ion-icon slot="start" name="log-in"></ion-icon>
                  <ion-label>
                    Login
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/support">
                  <ion-icon slot="start" name="help"></ion-icon>
                  <ion-label>
                    Support
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/signup">
                  <ion-icon slot="start" name="person-add"></ion-icon>
                  <ion-label>
                    Signup
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>
          <!--  slides are not working in ionic 4
            <ion-list>
              <ion-list-header>
                Tutorial
              </ion-list-header>
              <ion-menu-toggle auto-hide="false">
                <ion-item button (click)="openTutorial()">
                  <ion-icon slot="start" name="hammer"></ion-icon>
                  <ion-label>Show Tutorial</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>

           
            -->
            <ion-list >
              <ion-list-header>
                Legal
              </ion-list-header>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item button (click)="show2257Disclaimer()" >
                  <ion-icon slot="start" name="paper"></ion-icon>
                  <ion-label>
                    2257 Statement
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-menu-toggle auto-hide="false">
                <ion-item button (click)="showPrivacyPolicy()" >
                  <ion-icon slot="start" name="paper"></ion-icon>
                  <ion-label>
                    Privacy Policy
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
              <ion-menu-toggle auto-hide="false">
                <ion-item button (click)="showTermsOfService()" >
                  <ion-icon slot="start" name="paper"></ion-icon>
                  <ion-label>
                    Terms Of Service
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            
    <!--  <button style=" (click)="showMainPicRules();">18 U.S.C. 2257 Exemption/Disclosure Statement/button>
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/support">
                  <ion-icon slot="start" name="help"></ion-icon>
                  <ion-label>
                    Support
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
    
              <ion-menu-toggle auto-hide="false">
                <ion-item routerLink="/signup">
                  <ion-icon slot="start" name="person-add"></ion-icon>
                  <ion-label>
                    Signup
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            -->
            </ion-list>
          </ion-content>
        </ion-menu>
    <ion-router-outlet #RouterOutlet main></ion-router-outlet>
  
  </ion-split-pane>
  
  <simple-notifications style="z-index: 9999999;" [options]="options"></simple-notifications>
</ion-app>
