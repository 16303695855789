import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../../services/session.service';
import {ObjectFunctions } from '../object.functions';
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
        private session: SessionService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (ObjectFunctions.isValid(this.session.CurrentSession) === true && this.session.validSession() === true) {
            return true; // logged in so return true
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
