import 'rxjs/add/operator/map';
import { Api } from '../api/api';
import { Account, EventLocation, Filter, Screen } from '../../models/index';
import { of as observableOf } from 'rxjs';
import { EventsDashboard } from '../../models/index';
import { ServiceResult } from '../../models/serviceresult';
import * as i0 from "@angular/core";
import * as i1 from "../api/api";
var EventService = /** @class */ (function () {
    function EventService(api) {
        /*
        this.EventFilter.SortBy = 'StartDate';
        this.EventFilter.SortDirection = 'asc';
        this.EventFilter.PageSize = 50;
        this.EventFilter.StartIndex = 0;
        this.EventFilter.PageResults = true;
        */
        this.api = api;
        this.Categories = [];
        this.Properties = [];
        this.Accounts = [];
        // These are selected screens by user in the event-filter.ts
        // NOTE: in the filter dialog this only supports boolean fields i.e. private, active..
        // public EventScreens: Screen[] = [];
        //  public EventFilter: Filter = new Filter();
        this.AvailableScreens = []; // cache this
        this.Properties.push({
            name: 'private',
            caption: 'Include Private',
            isChecked: false
        });
    }
    EventService.prototype.addEvent = function (event) {
        return this.api.invokeRequest('POST', 'api/Events/Add', event);
    };
    EventService.prototype.addEventLocation = function (eventLocation) {
        return this.api.invokeRequest('POST', 'api/Events/Location/Insert', eventLocation);
    };
    EventService.prototype.deleteEvent = function (eventUUID) {
        return this.api.invokeRequest('POST', 'api/Events/Delete/' + eventUUID, '');
    };
    EventService.prototype.getDashboard = function (view, filter) {
        return this.api.invokeRequest('POST', 'api/Apps/Dashboard/' + view, filter);
    };
    EventService.prototype.getEvent = function (eventId) {
        if (this.Dashboard !== undefined && this.Dashboard.Events !== undefined) {
            for (var i = 0; i < this.Dashboard.Events.length; i++) {
                if (this.Dashboard.Events[i].UUID === eventId) {
                    var res = new ServiceResult();
                    res.Code = 200;
                    res.Result = this.Dashboard.Events[i];
                    return observableOf(res);
                }
            }
        }
        return this.api.invokeRequest('GET', 'api/EventBy/' + eventId, '');
    };
    EventService.prototype.getEventCategories = function () {
        return this.api.invokeRequest('GET', 'api/Events/Categories');
    };
    EventService.prototype.getEventLocation = function (eventUUID) {
        if (this.Dashboard !== undefined && this.Dashboard.Locations !== undefined) {
            for (var i = 0; i < this.Dashboard.Locations.length; i++) {
                if (this.Dashboard.Locations[i].EventUUID === eventUUID) {
                    var res = new ServiceResult();
                    res.Code = 200;
                    res.Result = this.Dashboard.Locations[i];
                    return observableOf(res);
                }
            }
        }
        return this.api.invokeRequest('GET', 'api/Events/Locations/' + eventUUID, '');
    };
    EventService.prototype.getEvents = function (filter) {
        return this.api.invokeRequest('POST', 'api/Events', filter);
    };
    // Returns reminders flagged as favorite
    EventService.prototype.getFavorites = function (filter) {
        return this.api.invokeRequest('POST', 'api/Events/Favorites', filter);
    };
    EventService.prototype.getHostEvents = function (accountUUID, filter) {
        return this.api.invokeRequest('POST', 'api/Events/Hosts/' + accountUUID, filter);
    };
    EventService.prototype.getPreviousLocations = function () {
        return this.api.invokeRequest('GET', 'api/Events/Locations/Account');
    };
    EventService.prototype.logOut = function () {
        this.Dashboard = null;
        this.Dashboard = new EventsDashboard();
        this.Categories = [];
        this.Properties = [];
        this.Accounts = [];
    };
    EventService.prototype.publishEvent = function (eventUUID) {
        return this.api.invokeRequest('POST', 'api/Events/' + eventUUID + '/Publish');
    };
    EventService.prototype.saveEventLocation = function (eventLocation) {
        return this.api.invokeRequest('POST', 'api/Events/Location/Save', eventLocation);
    };
    EventService.prototype.updateEvent = function (event) {
        return this.api.invokeRequest('PATCH', 'api/Events/Update', event);
    };
    EventService.prototype.updateEventLocation = function (eventLocation) {
        return this.api.invokeRequest('POST', 'api/Events/Location/Update', eventLocation);
    };
    EventService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventService_Factory() { return new EventService(i0.ɵɵinject(i1.Api)); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
