var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import 'rxjs/add/operator/map';
import { Session, Profile } from '../models/index';
import { Events } from '@ionic/angular';
import { Api } from './api/api';
// import * as moment from 'moment';
import { Storage } from '@ionic/storage';
import { LocalSettings } from '../services/settings/local.settings';
import { ObjectFunctions } from '../common/object.functions';
import * as jwt_decode from 'jwt-decode';
import { DateTimeFunctions } from '../common/date.time.functions';
import { Timer } from '../common/timer';
import * as i0 from "@angular/core";
import * as i1 from "./api/api";
import * as i2 from "@ionic/angular";
import * as i3 from "@ionic/storage";
var SessionService = /** @class */ (function () {
    function SessionService(api, messages, storage) {
        this.api = api;
        this.messages = messages;
        this.storage = storage;
        this.AccountRoles = [];
        this.CurrentSession = new Session();
    }
    SessionService.prototype.clearSession = function () {
        this.AccountRoles = [];
        this.CurrentSession = new Session();
        this.CurrentSession.IsAdmin = false;
        this.CurrentSession.Expires = new Date();
        this.CurrentSession.AccountUUID = '';
        this.CurrentSession.UserUUID = '';
        this.CurrentSession.LastSettingUUID = '';
        this.CurrentSession.IsPersistent = false;
        Api.authToken = '';
        this.CurrentSession.Profile = null;
        this.CurrentSession.Profile = new Profile();
        this.storage.remove(LocalSettings.SessionToken);
        this.storage.remove(LocalSettings.UserName);
        this.storage.remove(LocalSettings.SessionData);
        this.storage.remove(LocalSettings.HasLoggedIn);
    };
    SessionService.prototype.getRole = function (category, categoryName) {
        for (var i = 0; i < this.AccountRoles.length; i++) {
            if (this.AccountRoles[i].Category.toUpperCase() === category.toUpperCase() &&
                this.AccountRoles[i].CategoryRoleName.toUpperCase() === categoryName.toUpperCase()) {
                return this.AccountRoles[i];
            }
        }
        return null;
    };
    SessionService.prototype.getSession = function (sessionToken) {
        return this.api.invokeRequest('POST', 'api/Sessions', sessionToken);
    };
    SessionService.prototype.isUserInAdminRole = function () {
        if (ObjectFunctions.isNullOrWhitespace(Api.authToken) === true) {
            console.log('session.service.ts isUserInAdminRole invalid authtoken');
            return false;
        }
        var tokens = jwt_decode(Api.authToken);
        if (ObjectFunctions.isValid(tokens) === false) {
            console.log('session.service.ts isUserInAdminRole tokens:', tokens);
            return false;
        }
        console.log('session.service.ts isUserInAdminRole jwt_decode tokens:', tokens);
        if (ObjectFunctions.isValid(tokens.roleNames) === false) {
            console.log('session.service.ts isUserInAdminRole tokens.roleNames:', tokens.roleNames);
            return false;
        }
        var roleWeights = tokens.roleWeights.split(',');
        for (var i = 0; i < roleWeights.length; i++) {
            if (roleWeights[i] >= 90) {
                console.log('session.service.ts isUserInAdminRole TRUE');
                return true;
            }
        }
        console.log('session.service.ts isUserInAdminRole FALSE');
        return false;
    };
    SessionService.prototype.isUserInRole = function (roleName) {
        //  console.log('session.service.ts isUserInRole roleName:', roleName);
        // console.log('session.service.ts isUserInRole Api.authToken:', Api.authToken);
        if (ObjectFunctions.isNullOrWhitespace(Api.authToken) === true) {
            //  console.log('session.service.ts isUserInRole invalid authtoken');
            return false;
        }
        var tokens = jwt_decode(Api.authToken);
        if (ObjectFunctions.isValid(tokens) === false) {
            // console.log('session.service.ts isUserInRole tokens:', tokens);
            return false;
        }
        roleName = roleName.toUpperCase();
        // console.log('session.service.ts isUserInRole jwt_decode tokens:', tokens);
        if (ObjectFunctions.isValid(tokens.roleNames) === false) {
            // console.log('session.service.ts isUserInRole tokens.roleNames:', tokens.roleNames);
            return false;
        }
        console.log('split a session.service.ts');
        var roleNames = tokens.roleNames.split(',');
        for (var i = 0; i < roleNames.length; i++) {
            if (roleName === roleNames[i]) {
                //        console.log('session.service.ts isUserInRole TRUE');
                return true;
            }
        }
        // console.log('session.service.ts isUserInRole FALSE');
        return false;
    };
    // When app is loading is calls checkLoginStatus() (basically checks the session)
    // if it returns false then look for local storage to see if there's a key and/or
    // session data to load.
    //
    SessionService.prototype.loadSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tidx;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('session.service.ts loadSession');
                        tidx = Timer.start('session.service.ts loadSession');
                        return [4 /*yield*/, this.storage.get(LocalSettings.SessionToken).then(function (res) {
                                console.log('session.service.ts loadSession res:', res);
                                if (ObjectFunctions.isValid(res) === false) {
                                    console.log('SESSION.SERVICE.TS loadSession ObjectFunctions.isValid = false');
                                    return false; // no token so we can't load any data.
                                }
                                Api.authToken = res; // set the authorization token
                                console.log('SESSION.SERVICE.TS loadSession Api.authToken:', Api.authToken);
                                // Load the session data..
                                _this.storage.get(LocalSettings.SessionData).then(function (sData) {
                                    if (ObjectFunctions.isValid(sData) === false) {
                                        _this.getSession(Api.authToken).subscribe(function (sessionResponse) {
                                            var data = sessionResponse;
                                            if (data.Code !== 200) {
                                                // this.clearSession();
                                                if (data.Code === 401) {
                                                    // this.messages.publish('user:logout');
                                                    return;
                                                }
                                                _this.messages.publish('api:err', data);
                                                //
                                                return false;
                                            }
                                            _this.CurrentSession = data.Result;
                                            console.log('SESSION.SERVICE.TS loadSession api.getSession() this.CurrentSession:', _this.CurrentSession);
                                            _this.messages.publish('user:session.loaded');
                                            Timer.stop(tidx);
                                        });
                                    }
                                    else {
                                        _this.CurrentSession = sData;
                                        console.log('SESSION.SERVICE.TS loadSession storage.get() this.CurrentSession:', _this.CurrentSession);
                                        _this.messages.publish('user:session.loaded');
                                        Timer.stop(tidx);
                                    }
                                });
                                if (ObjectFunctions.isValid(_this.CurrentSession) === false) {
                                    console.log('SESSION.SERVICE.TS loadSession ObjectFunctions.isValid( this.CurrentSession) = false');
                                    // this.messages.publish('user:logout');
                                    return false;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SessionService.prototype.login = function (userCredentials) {
        console.log('SESSION.SERVICE.TS login this.api:', this.api);
        return this.api.invokeRequest('POST', 'api/Accounts/Login', userCredentials);
    };
    SessionService.prototype.logOut = function () {
        var _this = this;
        console.log('session.service.ts logOut this.api:', this.api);
        this.clearSession();
        if (this.api.invokeRequest('GET', 'api/Accounts/LogOut', '')) { // .then(() => {
            this.clearSession();
            return this.storage.remove(LocalSettings.HasLoggedIn).then(function () {
                return _this.storage.remove(LocalSettings.UserName);
            }).then(function () {
                // // this.messages.publish('user:logout');
            });
        }
    };
    SessionService.prototype.saveSessionLocal = function () {
        // this.storage.set(LocalSettings.SessionToken, Api.authToken);
        // this.storage.set(LocalSettings.UserName, this.login.UserName);
        this.storage.set(LocalSettings.SessionData, this.CurrentSession);
        // this.storage.set(LocalSettings.HasLoggedIn, true);
    };
    SessionService.prototype.validSession = function () {
        console.log('SESSION.SERVICE.TS validSession Api.authToken:', Api.authToken);
        if (ObjectFunctions.isValid(this.CurrentSession) === false ||
            ObjectFunctions.isNullOrWhitespace(this.CurrentSession.UserUUID) === true) {
            console.log('SESSION.SERVICE.TS validSession ObjectFunctions.isValid( this.CurrentSession) = false');
            // this.messages.publish('user:logout');
            return false;
        }
        // check if session has expired if not persistent.
        console.log('SESSION.SERVICE.TS validSession.this.CurrentSession.IsPersistent:', this.CurrentSession.IsPersistent);
        if (this.CurrentSession.IsPersistent === true) {
            return true; // has a session and it's persistant so no need to check expiration.
        }
        console.log('SESSION.SERVICE.TS validSession this.CurrentSession.expires:', this.CurrentSession.Expires);
        var diff = DateTimeFunctions.getDifference(new Date(), this.CurrentSession.Expires);
        console.log('SESSION.SERVICE.TS validSession getDifference:', diff);
        if (diff === false || diff <= 0) {
            console.log('SESSION.SERVICE.TS validSession RETURNING false');
            return false;
        }
        return true;
        /*
        const end = moment(new Date());
        const now =  moment(this.CurrentSession.Expires).local();
        const duration = moment.duration(now.diff(end));
        const ms = duration.asMilliseconds();

        console.log('SESSION.SERVICE.TS validSession ms left in session:', ms);

        if (ms > 0) {
            console.log('SESSION.SERVICE.TS validSession RETURNING TRUE:');
            return true;
        }
        return false;
        */
    };
    SessionService.prototype.isLocationSet = function () {
        console.log('session.service.ts isLocationSet');
        if (this.validSession() === true) {
            console.log('session.service.ts isLocationSet (this.sessionService.validSession() === true');
            if (ObjectFunctions.isValid(this.CurrentSession.Profile) === true &&
                ObjectFunctions.isValid(this.CurrentSession.Profile.LocationDetail) === true &&
                (this.CurrentSession.Profile.LocationDetail.Latitude !== '0' &&
                    this.CurrentSession.Profile.LocationDetail.Longitude !== '0')) {
                console.log('session.service.ts isLocationSet SETTING LOCATION Latitude:', this.CurrentSession.Profile.LocationDetail.Latitude);
                // tslint:disable-next-line:max-line-length
                console.log('session.service.ts isLocationSet SETTING LOCATION  Longitude:', this.CurrentSession.Profile.LocationDetail.Longitude);
                return true;
            }
        }
        return false;
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.Api), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.Storage)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
